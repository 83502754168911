import { RoomType, RoomTypeId } from "@/typings/RoomType";

const types = [
  {
    id: RoomTypeId.HAL,
    name: "Hal",
  },
  {
    id: RoomTypeId.LICHAAM,
    name: "Veranderingen in het lichaam",
  },
  {
    id: RoomTypeId.VOEDING,
    name: "Voeding & Spijsvertering",
  },
  {
    id: RoomTypeId.ENERGIE,
    name: "Energie & Bewegen",
  },
  {
    id: RoomTypeId.VOORZIENINGEN,
    name: "Voorzieningen & Regelgeving",
  },
  {
    id: RoomTypeId.WERK,
    name: "Werk & Actief leven",
  },
  {
    id: RoomTypeId.GOEDAFSCHEID,
    name: "Een goed afscheid",
  },
  {
    id: RoomTypeId.GEDACHTEN,
    name: "Gedachten & Emoties",
  },
  {
    id: RoomTypeId.ZINGEVING,
    name: "Zingeving",
  },
  {
    id: RoomTypeId.RELATIE,
    name: "Relaties & Omgeving",
  },
  {
    id: RoomTypeId.KINDEREN,
    name: "Kinderen",
  },
];

const getBackgroundUrl = (id: RoomTypeId): string =>
  `/img/kamer-bg/kamer_${id}.png`;

// Hal has no icon
const getIconUrl = (id: RoomTypeId): string | null =>
  id !== RoomTypeId.HAL ? `/img/kamer-icons/kamericoon_${id}.png` : null;

const getButtonUrl = (id: RoomTypeId): string =>
  `/img/kamer-buttons/ook_button_${id}.png`;

const getButtonActiveUrl = (id: RoomTypeId): string =>
  `/img/kamer-buttons/ook_button_${id}_select.png`;

const getHouseUrl = (id: RoomTypeId): string =>
  `/img/kamer-in-huis/ook_huis_${id}.png`;

const mapToRoomType = (type: { id: RoomTypeId; name: string }) => ({
  ...type,
  background: getBackgroundUrl(type.id),
  icon: getIconUrl(type.id),
  button: getButtonUrl(type.id),
  buttonActive: getButtonActiveUrl(type.id),
  houseImageUrl: getHouseUrl(type.id),
  hasText: type.id === RoomTypeId.HAL,
});

export default {
  index(): RoomType[] {
    return types.map(mapToRoomType);
  },
  default() {
    return mapToRoomType(types[0]);
  },
  show(id: RoomTypeId): RoomType {
    const type = types.find((type) => type.id === id);

    if (!type) {
      throw Error("Invalid Room Type Id");
    }

    return mapToRoomType(type);
  },
};
