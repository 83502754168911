import { Context } from "@/typings/Context";

const downloadDataUrl = (dataUrl: string, filename: string) => {
  const link = document.createElement("a");
  link.download = filename;
  link.href = dataUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default (context: Context) => {
  const download = () => {
    downloadDataUrl(context.getCanvas().toDataURL(), "Levenshuis.png");
  };

  return {
    download,
  };
};
