import { API, Res } from "@/services/config";
import { MediaPayload } from "@/typings/Media";
import { RoomPayload, UpdateRoomPayload } from "@/typings/Room";
import { RoomTypeId } from "@/typings/RoomType";

export default {
  show(houseId: string, roomTypeId: RoomTypeId): Res<RoomPayload> {
    return API.get(`/api/rooms/${houseId}/${roomTypeId}`);
  },

  // formData should contain a file attribute with the file
  upload(roomId: string, formData: FormData): Res<MediaPayload> {
    return API.post(`/api/rooms/${roomId}/upload-image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  update(roomId: string, payload: UpdateRoomPayload): Res<RoomPayload> {
    return API.put(`/api/rooms/${roomId}`, payload);
  },
};
