import { isActiveSelection } from "@/typings/ActiveSelection";
import { Context } from "@/typings/Context";
import {
  ActiveSelection,
  Object as FabricObject,
  Textbox,
} from "fabric/fabric-impl";

const propertiesToInclude = [
  "_controlsVisibility",
  "lockRotation",
  "transparentCorners",
];

const handleCopy = (context: Context) => {
  context
    .getCanvas()
    .getActiveObject()
    .clone(function (cloned: FabricObject) {
      window._clipboard = cloned;
    }, propertiesToInclude);
};

const handlePaste = (context: Context) => {
  const activeObj = context.getCanvas().getActiveObject();

  // Paste during isEditing should be used to paste text, not to paste a new object
  if ((activeObj as Textbox).isEditing) {
    return;
  }

  // clone again, so you can do multiple copies.
  window._clipboard.clone(function (clonedObj: FabricObject) {
    if (!clonedObj) {
      throw Error(
        "Could clone the clipboard, make sure canvas is set in the context"
      );
    }

    context.getCanvas().discardActiveObject();
    clonedObj.set({
      left: clonedObj.left ? clonedObj.left + 10 : 0,
      top: clonedObj.top ? clonedObj.top + 10 : 0,
      evented: true,
    });
    if (isActiveSelection(clonedObj)) {
      // active selection needs a reference to the canvas.
      clonedObj.canvas = context.getCanvas();
      (clonedObj as ActiveSelection).forEachObject((obj) =>
        context.getCanvas().add(obj)
      );
      // this should solve the unselectability
      clonedObj.setCoords();
    } else {
      context.getCanvas().add(clonedObj);
    }
    window._clipboard.top += 10;
    window._clipboard.left += 10;
    context.getCanvas().setActiveObject(clonedObj);
    context.getCanvas().requestRenderAll();
  }, propertiesToInclude);
};

const handleDelete = (context: Context) => {
  const activeObj = context.getCanvas().getActiveObject();

  // Delete during isEditing should be used to remove text, not the object itself
  if ((activeObj as Textbox).isEditing) {
    return;
  }

  if (isActiveSelection(activeObj)) {
    activeObj._objects.forEach((obj) => context.getCanvas().remove(obj));
    context.getCanvas().discardActiveObject();
  } else {
    context.getCanvas().remove(activeObj);
  }
};

export default (context: Context) => {
  const keyDownHandler = (event: KeyboardEvent) => {
    // Delete active element
    if (event.key === "Delete") {
      handleDelete(context);
    }

    // Copy active element
    if (event.ctrlKey && event.key === "c") {
      handleCopy(context);
    }

    // Paste active element
    if (event.ctrlKey && event.key === "v") {
      handlePaste(context);
    }
  };

  return {
    keyDownHandler,
  };
};
