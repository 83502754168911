import LinkBar from "@/components/LinkBar.vue";
import { Context } from "@/typings/Context";
import { mount } from "mount-vue-component";

export const initLinkBar = (context: Context) => {
  const { destroy, el: linkBarElement } = mount(LinkBar, {
    props: { context: context },
  });
  document.body.appendChild(linkBarElement);

  context.getCanvas().on("disposing", () => {
    destroy();
    document.body.removeChild(linkBarElement);
  });
};
