import RoomService from "@/services/RoomService";
import RoomTypeService from "@/services/RoomTypeService";
import { Context } from "@/typings/Context";
import { Room } from "@/typings/Room";
import { initActionsBar } from "@/util/initActionsBar";
import configureElement from "@/util/configureElement";
import getCanvasSize from "@/util/getCanvasSize";
import { initLinkBar } from "@/util/initLinkBar";
import { fabric } from "fabric";

function fixSelectingMultipleItems(context: Context) {
  context.getCanvas().on("selection:created", function (e) {
    // fix for when selecting multiple items, do not show controls, as this will allow
    // rotation and scaling
    if (Array.isArray(e.selected) && e.selected.length > 1) {
      const obj = context.getCanvas().getActiveObject();
      obj.set({ hasControls: false });
    }
  });
}

function setBackground(context: Context, room: Room) {
  context
    .getCanvas()
    .setBackgroundImage(
      RoomTypeService.show(room.type).background,
      context.getCanvas().renderAll.bind(context.getCanvas()),
      {
        originX: "left",
        originY: "top",
      }
    );
}

async function loadCanvasState(context: Context, room: Room) {
  if (room.content) {
    // Load from json triggers the "object:added" events, which triggers the
    // persistCanvasToDatabase handler. We do not want that because it messes up the
    // database state.
    context.disablePersistToDatabaseEvents();
    context.getCanvas().loadFromJSON(room.content, () => {
      context.enablePersistToDatabaseEvents();
      context.getCanvas().getObjects().forEach(configureElement);
    });
  }
}

function setupPersistCanvasStateListeners(context: Context, room: Room) {
  const propertiesToInclude = [
    "_controlsVisibility",
    "lockRotation",
    "transparentCorners",
    "id",
  ];

  const persistCanvasToDatabase = () => {
    if (!context.isPersistToDatabaseEventsEnabled()) return;

    const canvasData = JSON.stringify(
      context.getCanvas().toJSON(propertiesToInclude)
    );

    RoomService.update(room.id, { content: canvasData });
  };

  context.getCanvas().on("object:added", persistCanvasToDatabase);
  context.getCanvas().on("object:modified", persistCanvasToDatabase);
  context.getCanvas().on("object:removed", persistCanvasToDatabase);
  context.getCanvas().on("trigger-persist", persistCanvasToDatabase);

  // TODO persist while typing?
}

export default (context: Context) => {
  const resizeHandler = () => {
    const { height, width, zoom } = getCanvasSize();

    context.getCanvas().setHeight(height);
    context.getCanvas().setWidth(width);
    context.getCanvas().setZoom(zoom);
  };

  const initCanvas = async (room: Room) => {
    context.setCanvas(
      new fabric.Canvas("canvas", {
        // width, height and zoom be overwritten by de resizeHandler
        backgroundColor: "white",
      })
    );

    await loadCanvasState(context, room);
    setupPersistCanvasStateListeners(context, room);

    fixSelectingMultipleItems(context);
    setBackground(context, room);
    resizeHandler();

    initActionsBar(context);
    initLinkBar(context);
  };

  return {
    initCanvas,
    resizeHandler,
  };
};
