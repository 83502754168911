export default () => {
  let width = window.innerWidth - 500; // decrease some padding
  let height = width * (1048 / 1409);

  const maxHeight = window.innerHeight - 25; // deduct footer and header height
  if (height > maxHeight) {
    height = maxHeight;
    width = height * (1409 / 1048);
  }

  // At 1080 width the zoom should be 1.
  const zoom = width / 1409;

  return { height, width, zoom };
};
