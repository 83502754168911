import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircle,
  faDoorClosed,
  faDownload,
  faFileLines,
  faHeadset,
  faImage,
  faPencil,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import * as Sentry from "@sentry/vue";
import { createApp } from "vue";
import App from "./App.vue";

/* add icons to the library */
library.add({
  faImage,
  faFileLines,
  faDoorClosed,
  faHeadset,
  faPencil,
  faTrashCan,
  faCircle,
  faDownload,
});

const app = createApp(App);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_URL,

  // disable performance monitoring for now
  tracesSampleRate: 0,
});

app.mount("#app");
