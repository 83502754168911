export const postItColors = [
  {
    key: "yellow",
    hex: "#fff2ca",
    className: "color-yellow",
  },
  {
    key: "pink",
    hex: "#ffe3e3",
    className: "color-pink",
  },
  {
    key: "blue",
    hex: "#ccf5ff",
    className: "color-blue",
  },
];

export const defaultPostItColor = postItColors[0];
