import { isImage } from "@/typings/Image";
import { isTextbox } from "@/typings/Textbox";
import { Image, Object as FabricObject, Textbox } from "fabric/fabric-impl";

const updateElementFontsize = (object: Textbox) => {
  if (!object.text) {
    return;
  }

  let newFontSize;
  if (object.text.length < 14) {
    newFontSize = 30;
  } else if (object.text.length < 30) {
    newFontSize = 24;
  } else if (object.text.length < 56) {
    newFontSize = 18;
  } else if (object.text.length < 140) {
    newFontSize = 14;
  } else {
    newFontSize = 12;
  }

  object.fontSize = newFontSize;
};

const configureTextbox = (object: Textbox) => {
  object.on("editing:entered", () => {
    object.hiddenTextarea?.setAttribute("maxlength", "200");
  });

  object.on("changed", () => {
    object.set("width", 200);
    updateElementFontsize(object);
  });

  updateElementFontsize(object);

  object.set("width", 200);

  object.setControlsVisibility({
    bl: false,
    br: false,
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    tl: false,
    tr: false,
    mtr: false,
  });
};

const configureImage = (object: Image) => {
  object.setControlsVisibility({
    bl: true,
    br: true,
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    tl: true,
    tr: true,
    mtr: false,
  });
};

export default (object: FabricObject) => {
  object.transparentCorners = false;

  if (isTextbox(object)) {
    configureTextbox(object);
  }

  if (isImage(object)) {
    configureImage(object);
  }
};
