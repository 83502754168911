import { ImageWithId } from "@/typings/Image";
import { TextboxWithId } from "@/typings/Textbox";
import { Object as FabricObject } from "fabric/fabric-impl";

export interface CanvasModifiedObject {
  obj: FabricObject;
  id: string;
}

export interface CanvasAddedImageObject extends CanvasModifiedObject {
  obj: ImageWithId;
  imageId: string;
}

export interface CanvasAddedTextboxObject extends CanvasModifiedObject {
  obj: TextboxWithId;
}

export const isCanvasAddedTextboxObject = (object: {
  obj: FabricObject;
}): object is CanvasAddedTextboxObject =>
  "obj" in object && object.obj.type === "textbox";

export const isCanvasAddedImageObject = (object: {
  obj: FabricObject;
}): object is CanvasAddedImageObject =>
  "obj" in object && object.obj.type === "image";
