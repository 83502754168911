import { defaultPostItColor } from "@/constants/postItColors";
import { Context } from "@/typings/Context";
import { TextboxWithId } from "@/typings/Textbox";
import configureElement from "@/util/configureElement";
import { fabric } from "fabric";
import { Shadow } from "fabric/fabric-impl";
import { v1 as uuid } from "uuid";

export interface AddTextOptions {
  color?: string | null;
  id?: string;
  notifyRoom?: boolean;
  setActive?: boolean;
}

export default (context: Context) => {
  const addText = (options: AddTextOptions) => {
    const color = options.color ?? defaultPostItColor.hex;
    const notifyRoom = options.notifyRoom ?? true;
    const setActive = options.setActive ?? true;
    const id = options.id ?? uuid();

    const textEl = new fabric.Textbox("", {
      fontFamily: "Open Sans",
      textAlign: "center",
      fontSize: 24,
      left: 100,
      top: 100,
      width: 200,
      strokeWidth: 24,
      transparentCorners: false,
      backgroundColor: color ?? defaultPostItColor.hex,
      shadow: { color: "rgba(0,0,0,0.2)", offsetY: 6, blur: 15 } as Shadow,
      lockScalingX: true,
      lockScalingY: true,
      lockUniScaling: false,
      lockRotation: true,
    }) as TextboxWithId;

    textEl.set({ id });

    configureElement(textEl);

    context.getCanvas().add(textEl);

    if (setActive) {
      context.getCanvas().setActiveObject(textEl);
      textEl.selectAll();
      textEl.enterEditing();
      textEl.hiddenTextarea?.focus();
    }

    if (notifyRoom) {
      const modifiedObj = {
        obj: textEl,
        id: textEl.id,
      };

      context.getCanvas().fire("sockets:object-added", modifiedObj);
    }
  };

  return {
    addText,
  };
};
