import ObjectActionsBar from "@/components/ObjectActionsBar.vue";
import { Context } from "@/typings/Context";
import { mount } from "mount-vue-component";

export const initActionsBar = (context: Context) => {
  const updateLastUsedPostItColorHex = (hex: string) => {
    context.lastUsedPostItColorHex = hex;
  };

  const { destroy, el: objectActionsBarElement } = mount(ObjectActionsBar, {
    props: { context: context, updateLastUsedPostItColorHex },
  });
  document.body.appendChild(objectActionsBarElement);

  context.getCanvas().on("disposing", () => {
    destroy();
    document.body.removeChild(objectActionsBarElement);
  });
};
