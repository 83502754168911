export interface RoomType {
  id: RoomTypeId;
  background: string;
  name: string;
  icon: string | null;
  button: string;
  buttonActive: string;
  houseImageUrl: string;
  hasText: boolean;
}

export enum RoomTypeId {
  HAL = "hal",
  ENERGIE = "energie",
  GEDACHTEN = "gedachten",
  GOEDAFSCHEID = "goedafscheid",
  KINDEREN = "kinderen",
  LICHAAM = "lichaam",
  RELATIE = "relatie",
  VOEDING = "voeding",
  WERK = "werk",
  VOORZIENINGEN = "voorzieningen",
  ZINGEVING = "zingeving",
}
