import { API } from "@/services/config";
import {
  CanvasModifiedObject,
  isCanvasAddedImageObject,
  isCanvasAddedTextboxObject,
} from "@/typings/CanvasModifiedObject";
import { Context } from "@/typings/Context";
import { ImageWithId } from "@/typings/Image";
import { RoomTypeId } from "@/typings/RoomType";
import { RoomQuestionsFieldPayload, Sockets } from "@/typings/Sockets";
import { TextboxWithId } from "@/typings/Textbox";
import { SocketUser } from "@/typings/User";
import { Object as FabricObject } from "fabric/fabric-impl";
import Pusher from "pusher-js";

Pusher.logToConsole = process.env.NODE_ENV !== "production";

const sockets: Sockets = {
  pusher: null,
  roomChannel: null,
  getRoomChannel() {
    if (!this.roomChannel) {
      throw new Error(
        "No roomChannel. Call connect() before using the roomChannel"
      );
    }

    return this.roomChannel;
  },
  connect(houseId: string, roomType: RoomTypeId): Sockets {
    this.pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
      cluster: "eu",
      authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: API.defaults.headers.common["Authorization"],
        },
      },
    });

    this.roomChannel = this.pusher.subscribe(
      `presence-house.${houseId}.room.${roomType}`
    );
    this.roomChannel.bind(
      "pusher:subscription_succeeded",
      (user: SocketUser) => {
        console.log("here", user);
      }
    );
    this.roomChannel.bind("pusher:member_added", (user: SocketUser) => {
      console.log("added", user);
    });
    this.roomChannel.bind("pusher:member_removed", (user: SocketUser) => {
      console.log("removed", user);
    });

    return this;
  },
  disconnect(): Sockets {
    this.pusher?.disconnect();

    return this;
  },
  registerClientEventHandlers({ context, addText, addImageToCanvas }): Sockets {
    // Register object creation handlers
    this.getRoomChannel().bind(
      "client-object-added",
      (data: CanvasModifiedObject) => {
        if (isCanvasAddedTextboxObject(data)) {
          addText({
            id: data.id,
            color: data.obj.backgroundColor,
            notifyRoom: false,
            setActive: false,
          });
        }
        if (isCanvasAddedImageObject(data)) {
          addImageToCanvas({
            id: data.id,
            imageId: data.imageId,
            notifyRoom: false,
          });
        }
      }
    );

    // Register object move handler
    this.getRoomChannel().bind(
      "client-object-moved",
      (data: CanvasModifiedObject) => {
        const { obj, id } = data;
        context
          .getCanvas()
          .getObjects()
          .forEach((object: FabricObject) => {
            if ((object as TextboxWithId | ImageWithId).id === id) {
              context.getCanvas().fire("actions-bar:hide");

              object.set(obj);
              object.setCoords();
              context.getCanvas().renderAll();
            }
          });
      }
    );

    // register object modified handler
    this.getRoomChannel().bind(
      "client-object-modified",
      (data: CanvasModifiedObject) => {
        const { obj, id } = data;
        context
          .getCanvas()
          .getObjects()
          .forEach((object: FabricObject) => {
            if ((object as TextboxWithId | ImageWithId).id === id) {
              object.set(obj);
              object.setCoords();
              context.getCanvas().renderAll();
            }
          });
      }
    );

    // register object delete handler
    this.getRoomChannel().bind(
      "client-object-deleted",
      (data: CanvasModifiedObject) => {
        const { id } = data;
        context
          .getCanvas()
          .getObjects()
          .forEach((object: FabricObject) => {
            if ((object as TextboxWithId | ImageWithId).id === id) {
              context.getCanvas().fire("actions-bar:hide");
              context.getCanvas().remove(object);
            }
          });
      }
    );

    return this;
  },
  registerClientEventTriggers(context: Context): Sockets {
    /**
     * Custom socket events
     */
    context.getCanvas().on("sockets:object-added", (event) => {
      this.getRoomChannel().trigger(
        "client-object-added",
        event as unknown as CanvasModifiedObject
      );
    });
    context.getCanvas().on("sockets:object-modified", (event) => {
      this.getRoomChannel().trigger(
        "client-object-modified",
        event as unknown as CanvasModifiedObject
      );
    });

    /**
     * Hook directly into these fabricjs events
     */
    let isBeingThrottled = false;
    context.getCanvas().on("object:moving", (event) => {
      const triggerEvent = () => {
        const modifiedObj = {
          obj: event.target,
          id: (event.target as TextboxWithId | ImageWithId).id,
        };

        this.getRoomChannel().trigger(
          "client-object-moved",
          modifiedObj as CanvasModifiedObject
        );
      };

      if (!isBeingThrottled) {
        triggerEvent();
        isBeingThrottled = true;

        // 100ms is high enough to stay away from the client event throttling from pusher
        // If we implement our own websockets server, we might be able to make this faster
        setTimeout(() => {
          isBeingThrottled = false;
        }, 100);
      }
    });

    context.getCanvas().on("object:modified", (event) => {
      const modifiedObj = {
        obj: event.target,
        id: (event.target as TextboxWithId | ImageWithId).id,
      };

      this.getRoomChannel().trigger(
        "client-object-modified",
        modifiedObj as CanvasModifiedObject
      );
    });

    context.getCanvas().on("object:removed", (event) => {
      const deletedObj = {
        id: (event.target as TextboxWithId | ImageWithId).id,
      };

      this.getRoomChannel().trigger(
        "client-object-deleted",
        deletedObj as CanvasModifiedObject
      );
    });

    return this;
  },

  /**
   * Text field handlers
   */
  registerRoomTextFieldHandlers(updateText): Sockets {
    this.getRoomChannel().bind(
      "client-text-field-update",
      (text: string | null) => {
        updateText(text ?? "");
      }
    );

    return this;
  },
  triggerRoomTextFieldEvent(text: string | null): Sockets {
    this.getRoomChannel().trigger("client-text-field-update", text);

    return this;
  },

  /**
   * Question handlers
   */
  registerRoomQuestionsFieldHandlers(updateAnswers): Sockets {
    this.getRoomChannel().bind(
      "client-room-questions-field-update",
      (payload: RoomQuestionsFieldPayload) => {
        updateAnswers(payload);
      }
    );

    return this;
  },
  triggerRoomQuestionsFieldEvent(payload: RoomQuestionsFieldPayload): Sockets {
    this.getRoomChannel().trigger(
      "client-room-questions-field-update",
      payload
    );

    return this;
  },
};

export default sockets;
