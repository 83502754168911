import MediaService from "@/services/MediaService";
import RoomService from "@/services/RoomService";
import { Context } from "@/typings/Context";
import { ImageWithId } from "@/typings/Image";
import { Room } from "@/typings/Room";
import configureElement from "@/util/configureElement";
import { fabric } from "fabric";
import { Image as FabricImage } from "fabric/fabric-impl";
import { v1 as uuid } from "uuid";

export interface AddImageOptions {
  imageId: string;
  id?: string;
  notifyRoom?: boolean;
}

const maxHeight = 200;
const maxWidth = 225;

const setMaxResolution = (oImg: FabricImage) => {
  if (!oImg.width || !oImg.height) {
    return;
  }

  let height = oImg.height;
  if (oImg.width > maxWidth) {
    const scale = maxWidth / oImg.width;
    oImg.scale(maxWidth / oImg.width);
    height = oImg.height * scale;
  }
  if (height > maxHeight) {
    oImg.scale(maxHeight / height);
  }
};

const setPosition = (oImg: FabricImage) => {
  oImg.set({ top: 100, left: 100 });
};

const getImageInput = (): HTMLInputElement => {
  const imageInput = document.createElement("input");
  imageInput.type = "file";
  imageInput.id = "image-input";
  imageInput.accept = "image/jpeg, image/png, image/jpg";
  imageInput.style.display = "none";
  document.body.appendChild(imageInput);

  return imageInput;
};

export default (context: Context) => {
  let currentRoom: Room | null = null;

  const imageInput: HTMLInputElement =
    (document.getElementById("image-input") as HTMLInputElement | null) ??
    getImageInput();

  imageInput.addEventListener("change", function () {
    if (!imageInput.files || imageInput.files.length === 0) {
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      if (!imageInput.files) {
        throw Error("Cannot load. No images are selected");
      }
      if (!currentRoom) {
        throw Error("Cannot load. Current Room is not set");
      }

      const form = new FormData();
      form.append("file", imageInput.files[0]);
      const response = await RoomService.upload(currentRoom.id, form);

      addImageToCanvas({ notifyRoom: true, imageId: response.data.data.id });
    });
    reader.readAsDataURL(imageInput.files[0]);
  });

  const uploadImage = (room: Room) => {
    currentRoom = room;
    imageInput.value = "";
    imageInput.click();
  };

  function imageExists(url: string) {
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
      img.onerror = () => resolve(false);
      img.onload = () => resolve(true);
    });
  }

  function timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const getImageUntillItExists = async (
    imageId: string,
    maxTries = 5,
    currentTry = 0
  ): Promise<string> => {
    // Make sure this doesn't run forever.
    if (currentTry === maxTries) {
      throw Error("max tries reached. Cannot find image");
    }
    currentTry++;

    const response = await MediaService.show(imageId);
    const url = response.data.data.url_resized;

    if (await imageExists(url)) {
      return url;
    }

    await timeout(1000);
    return getImageUntillItExists(imageId, maxTries, currentTry);
  };

  const addImageToCanvas = async (options: AddImageOptions) => {
    const url = await getImageUntillItExists(options.imageId);

    fabric.Image.fromURL(
      url,
      function (oImg) {
        setMaxResolution(oImg);
        setPosition(oImg);
        configureElement(oImg);
        context.getCanvas().add(oImg);

        const id = options.id ?? uuid();
        (oImg as ImageWithId).set({ id });

        if (options.notifyRoom) {
          const addedObject = {
            obj: oImg,
            imageId: options.imageId,
            id: (oImg as ImageWithId).id,
          };

          context.getCanvas().fire("sockets:object-added", addedObject);
        }
      },
      {
        lockScalingFlip: true,
        transparentCorners: false,
        lockRotation: true,
      }
    );
  };

  return {
    uploadImage,
    addImageToCanvas,
  };
};
